.hitron-color {
  color: #004c97;
}
.local-color {
  color: #ff974c;
}
.hitron-input {
  &:focus {
    outline-color: #004c97;
  }
}
.local-input {
  &:focus {
    outline-color: #ff974c;
  }
}
.hitron-button {
  border: 2px solid #004c97;
  color: #004c97;
  &:hover {
    background: #004c97;
  }
  &:disabled {
    color: #004c97 !important;
  }
}
.local-button {
  border: 2px solid #ff974c;
  color: #ff974c;
  &:hover {
    background: #ff974c;
  }
  &:disabled {
    color: #ff974c !important;
  }
}
.login-page-wrapper {
  // height: 100vh;
  width: 100vw;
  background: #f0f0f3;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  .login-card {
    padding: 2.5rem;
    width: 680px;
    // height: 590px;
    box-shadow: -1px -1px 3px #ffffff, 1.5px 1.5px 3px rgba(174, 174, 192, 0.4);
    position: relative;
    &-text {
      margin: 3rem 0 1.5rem 0;
      justify-content: center;
      text-align: center;
      font-size: 21px;
      line-height: 1.5;
      // font-weight: 500;
    }
    &-logo {
      display: flex;
      justify-content: center;
    }
    &-label {
      line-height: 1.5;
    }
    &-input {
      box-sizing: border-box;
      width: 100%;
      font-size: 100%;
      font-weight: 400;
      border-radius: 0.5rem;
      background: #f0f0f3;
      box-shadow: -1px -1px 3px #ffffff, 1.5px 1.5px 3px rgba(174, 174, 192, 0.4);
      padding: 0.75rem;
      padding-right: 50px;
      border: none;
      line-height: 24px;
      margin-top: 0.75rem;
      margin-bottom: 0.75rem;
      &:focus {
        border-color: #e5e7eb;
      }
    }
    &-passwordIcon {
      right: 1rem;
      top: 1.5rem;
      position: absolute;
      cursor: pointer;
    }
    &-passwordForgot {
      cursor: pointer;

      display: flex;
      justify-content: end;
      font-weight: 500;
      &:hover {
        text-decoration: underline;
      }
      p {
        margin: 0;
      }
    }
    &-submit {
      border-radius: 8px;
      padding: 0.5rem;

      justify-content: space-between;
      align-items: center;
      display: flex;
      width: 100px;
      font-size: 100%;
      // position: absolute;
      // bottom: 10%;
      // left: 43%;
      cursor: pointer;
      &:hover {
        color: #ffffff;
      }
      &:disabled {
        cursor: no-drop;

        background: unset;
      }
    }
    &-resetSubmit {
      margin: 3rem auto;

      border-radius: 8px;
      padding: 0.5rem;

      justify-content: center;
      align-items: center;
      display: flex;
      width: 200px;
      height: 44px;
      font-size: 100%;
      &:hover {
        color: #ffffff;
      }
    }
    .ErrorMessage {
      color: #ff8388;
    }
    .HitronErrorMessage {
      color: #004c97;
    }
  }
}
.with-footer {
  height: calc(100vh - 74px);
}
.with-out-footer {
  height: 100vh;
}
.bg-f0f0f3 {
  background: #f0f0f3;
}
