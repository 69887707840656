.security_overview_wrapper{
  overflow-y: auto;
  padding-bottom: 80px;
  .security_overview {
      background: #ffffff;
      // max-width: 1444px;
      width: 100%;
      border-radius: 10px;
      padding: 26px 40px 28px 32px;
    
      .title {
        font-family: SFProDisplay-Regular, sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        color: #282828;
      }
      .security_overview_main_block{
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
        column-gap: 32px;
        @media (max-width: 778px) {
          grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
        }
      }
    
      .tile_container {
        display: flex;
        flex-direction: row;
        gap: 16px;
        justify-content: space-between;
        width: 100%;
    
        .tile {
          display: flex;
          align-items: center;
          background: #ffffff;
          border: 1px solid #eff2fc;
          border-radius: 10px;
          padding: 52.16px 28.98px 52.18px 34.1px;
          min-width: 312px;
          justify-content: space-between;
          margin-top: 13px;
    
          //box-shadow: -1px -1px 3px #FFFFFF, 1.5px 1.5px 3px rgba(174, 174, 192, 0.4);
    
          box-shadow: -1px -1px 3px #ffffff,
            1.5px 1.5px 3px rgba(174, 174, 192, 0.4);
    
          font-family: SFProDisplay-Bold, sans-serif;
          font-size: 14px;
          line-height: 17px;
          color: #282828;
        }
      }
    }
    .security_overview_inner{
      // display: flex;
      // column-gap: 32px;
      margin: 32px 0 64px;
    }
}
  
  .score_card_container {
    display: flex;
    gap: 32px;
    margin-top: 32px;
  }
  