.tile_container {
    display: flex;
    flex-direction: row;
    gap: 16px;
    justify-content: space-between;
    width: 100%;
  
    .tile {
      display: flex;
      align-items: center;
      background: #ffffff;
      border: 1px solid #eff2fc;
      border-radius: 10px;
      padding: 52.16px 28.98px 52.18px 34.1px;
      width: 100%;
      // min-width: 312px;
      justify-content: space-between;
      margin-top: 13px;
  
      //box-shadow: -1px -1px 3px #FFFFFF, 1.5px 1.5px 3px rgba(174, 174, 192, 0.4);
  
      box-shadow: -1px -1px 3px #ffffff, 1.5px 1.5px 3px rgba(174, 174, 192, 0.4);
  
      font-family: SFProDisplay-Bold, sans-serif;
      font-size: 14px;
      line-height: 17px;
      color: #282828;
      .switch_main{
        margin-left: 8px;
        .switch_label{
          position: relative;
          display: inline-flex;
          align-items: center;
          cursor: pointer;
          .peer{
            position: absolute;
            width: 1px;
            height: 1px;
            padding: 0;
            margin: -1px;
            overflow: hidden;
            clip: rect(0, 0, 0, 0);
            white-space: nowrap;
            border-width: 0;
            &:focus{
              outline: none;
              border: 4px sloid rgb(30 64 175);
            }

          }
          .switch_circle{
            width: 44px;
            height: 24px;
            border-radius: 50px;
            background-color: rgb(229 231 235);
            &::after{
              content: "";
              position: absolute;
              top: 2px;
              left: 2px;
              background-color: #ffffff;
              border: 1px solid gray;
              border-radius: 50%;
              height: 20px;
              width: 20px;
              transition: all;
            }
          }
          .peer-checked{
            background-color: rgb(37 99 235);;
            &::after{
              transform: translateX(100%);
              border-color: #ffffff;

            }
          }
        }
      }
    }
  }
  