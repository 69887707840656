.search-input {
  box-sizing: border-box;
  width: 450px;
  height: 60px;
  font-size: 20px;
  border-radius: 15px;
  border: 2px solid #eff2fc;
  outline: none;
  padding: 13px 36px 13px 60px;
  background: #ffffff;
  &:active {
    outline: none;
  }
}

.search-input-container {
  position: relative;
  margin: 0 20px 0 20px;
  .search-glass {
    position: absolute;
    top: 16px;
    left: 20px;
  }
  .searchData {
    position: absolute;
    width: 100%;
    border: 2px solid #eff2fc;
    border-top: none;
    box-sizing: border-box;
    border-radius: 0 0 15px 15px;
    background: #ffffff;
    max-height: 350px;
  }
}