.table {
  border-spacing: 0;
  border: none;
  font-family: SFProDisplay-Light, sans-serif;
  color: #282828;
  overflow: hidden;

  thead {
    tr {
      th {
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 21px;
        border: none;
      }
    }
  }

  tbody {
    border: none;
    color: #282828 !important;

    tr {
      background: #ffffff;
      border-radius: 10px;
      border: none;
      margin-bottom: 10px;

      td {
        padding: 10px 0;
        border: none;
      }
      &:not(:last-child) {
        margin-bottom: 4px;
      }
    }
  }
}

.table_container {
  overflow: hidden;

  margin-bottom: 12px;
  /* For Firefox */
  scrollbar-width: none;
  -ms-overflow-style: none;

  /* For Webkit browsers */
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}

.problems {
  width: 38px;
  height: 38px;
  border-radius: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  font-family: SFProDisplay-Light, sans-serif;
}
.healthStatus {
  display: inline-flex;
  align-items: center;
  gap: 20px;
}
.fmVersion {
  display: inline-flex;
  align-items: center;
  gap: 4px;
}

.table-wrapper {
  background: #f7f8fe;
  padding: 0 24px;
  .empty-data {
    display: flex;
    width: 100%;
    height: 400px;
    min-width: 400px;
    align-items: center;
    justify-content: center;
    .empty-text {
      font-size: 18px;
      font-weight: 500;
    }
  }
}
.loading_wrapper {
  height: 450px;
  width: calc(100% - 50px);
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading-hitron-wrapper {
  .ant-spin-dot,
  .ant-spin-dot-spin {
    margin-left: 15px !important;
    i {
      background-color: #004c97 !important;
    }
  }
}
.loading-local-wrapper {
  .ant-spin-dot,
  .ant-spin-dot-spin {
    margin-left: 15px !important;
    i {
      background-color: #ff8181 !important;
    }
  }
}
.customers_footer {
  .MuiTablePagination {
    &-selectLabel {
      margin-bottom: 0 !important;
    }
    &-displayedRows {
      margin-bottom: 0 !important;
    }
  }
  .MuiInputBase-root.MuiInputBase-colorPrimary.MuiTablePagination-input {
    line-height: 2;
  }
}
