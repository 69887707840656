.headerWrapper {
  position: sticky;
  top: 0;
  background: #ffffff;
  z-index: 999999;
  box-sizing: border-box;
  display: flex;
  background-color: #eff2fc;
  width: 100%;
  padding-bottom: 2px;
  &-logo {
    display: flex;
    width: fit-content;
    padding: 40px 24px 40px 40px;
    background-color: #ffffff;
  }
  .headerActions {
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: #ffffff;
  }
}
