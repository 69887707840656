.service-main-Wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  flex-wrap: wrap;
  .service-inner-Wrapper {
    width: 350px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 10px;
    padding: 35px 30px;
    height: 178px;
    box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
    .service-main-block {
      padding: 12px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      gap: 0 6px;
      .serviceTitle {
        font-family: SFProDisplay-Medium, sans-serif;
        font-size: 16px;
      }
      .loadingText {
        color: #ffb600;
      }
      .service-tag {
        color: #fff;
        font-size: 13px;
        border-radius: 25px;
        padding: 2px 8px;
        font-family: SFProDisplay-Light, sans-serif;
      }
      .bgActive {
        background-color: #9dd69a;
      }
      .bgInActive {
        background-color: #ff8284;
      }
      .bgComming {
        background-color: #ffd45e;
      }
      .hitronbgActive {
        background-color: #00cc66;
      }
      .hitronbgInActive {
        background-color: #dc4405;
      }
      .hitronbgComming {
        background-color: #daaa00;
      }
    }
    .service-inner-block {
      margin: 12px auto 0;
      width: 100%;
      .service-button {
        display: block;
        text-align: center;
        color: #fff;
        width: 100%;
        padding: 8px 24px;
        font-size: 14px;
        border-radius: 6px;
        cursor: pointer;
        text-decoration: none;
        border: none;
      }
      .notAllow {
        cursor: not-allowed;
      }
    }
    .service-router-btn {
      display: block;
      text-align: center;
      color: #fff;
      width: 100%;
      padding: 19px 24px;
      font-size: 14px;
      border-radius: 6px;
      cursor: pointer;
      text-decoration: none;
      border: none;
    }
  }
}
