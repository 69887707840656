.device-control {
  // height: calc(100vh - 242px);
  // overflow: hidden;
  .loading_wrapper {
    height: 130px !important;
  }
  .title {
    font-size: 23px;
  }
  .active-bg {
    background-color: rgba(230, 241, 254, 1);
  }
  .ant-progress-inner {
    width: 44px !important;
    height: 44px !important;
  }
  table {
    thead {
      tr {
        border-bottom: 2px solid #e0e0e0;

        td {
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 21px;
          border: none;
          padding: 25px 25px;
          color: #969696;
          @apply font-sf-light;
        }
      }
    }

    tbody {
      border: none;
      color: #282828 !important;

      tr {
        background: #ffffff;
        border-radius: 10px;
        border-bottom: 2px solid #e0e0e0;
        margin-bottom: 10px;

        td {
          border: none;
          padding: 16px 16px;
          text-align: left;
          @apply font-sf-light;
        }

        &:not(:last-child) {
          margin-bottom: 4px;
        }
      }
    }
  }
}
.modal_input {
  border-radius: 6px !important;
  input {
    border-radius: 6px 0px 0px 6px;
    border: 1px solid #d9d9d900;
    border-right: none;
  }
}
.sec-color {
  color: rgba(93, 102, 112, 1);
}
@media only screen and (max-width: 959px) {
  .device-control {
    height: auto;
    overflow: auto;
  }
}
