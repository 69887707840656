.table {
  border-spacing: 0;
  border: none;
  font-family: SFProDisplay-Light, sans-serif;
  color: #282828;
  overflow: hidden;

  thead {
    tr {
      th {
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 21px;
        border: none;
      }
    }
  }

  tbody {
    border: none;
    color: #282828 !important;

    tr {
      background: #ffffff;
      border-radius: 10px;
      border: none;
      margin-bottom: 10px;

      td {
        padding: 10px 0;
        border: none;
      }
      &:not(:last-child) {
        margin-bottom: 4px;
      }
    }
  }
}

.table_container {
  overflow: hidden;

  margin-bottom: 12px;
  /* For Firefox */
  scrollbar-width: none;
  -ms-overflow-style: none;

  /* For Webkit browsers */
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}

.problems {
  width: 38px;
  height: 38px;
  border-radius: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  font-family: SFProDisplay-Light, sans-serif;
}
.healthStatus {
  display: inline-flex;
  align-items: center;
  gap: 20px;
}
.fmVersion {
  display: inline-flex;
  align-items: center;
  gap: 4px;
}
.device_table_wrapper {
  background-color: #f7f8fe;
  padding: 0 24px;
  .table-wrapper {
    background: #f7f8fe;
    padding: 0;
    .empty-data {
      display: flex;
      width: 100%;
      height: 400px;
      min-width: 400px;
      align-items: center;
      justify-content: center;
      .empty-text {
        font-size: 18px;
        font-weight: 500;
      }
    }
    &_loader {
      height: 750px;
      display: flex;
      align-items: center;
    }
  }
  table {
    &::-webkit-scrollbar {
      width: 14px;
    }
    &::-webkit-scrollbar-track {
      background: #eff2fc00;
    }

    &::-webkit-scrollbar-thumb {
      background: rgb(176, 191, 209);
      background: radial-gradient(circle, rgb(57, 57, 58) 72%, rgb(43, 43, 43) 95%);
      border-radius: 8px;
      border-right: none;
      border-left: none;
      width: 10px;
      border: 2px solid #ffffff;
    }
    &::-webkit-scrollbar-track-piece:end {
      background: #ffffff;
      /* margin-bottom: 10px; */
    }

    &::-webkit-scrollbar-track-piece:start {
      background: #ffffff;
      /* margin-top: 10px; */
    }
  }
}

.customers_footer {
  .MuiTablePagination {
    &-selectLabel {
      margin-bottom: 0 !important;
    }
    &-displayedRows {
      margin-bottom: 0 !important;
    }
  }
  .MuiInputBase-root.MuiInputBase-colorPrimary.MuiTablePagination-input {
    line-height: 2;
  }
}

.fw-arrow {
  margin-top: 0.15rem;
}
.affected_devices {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}

.authorize_gateway {
  .ant-modal-content {
    border-radius: 12px;
    box-shadow: 0px 12px 25px -14px rgba(255, 255, 255, 0.75);
    -webkit-box-shadow: 0px 12px 25px -14px rgba(255, 255, 255, 0.75);
    -moz-box-shadow: 0px 12px 25px -14px rgba(255, 255, 255, 0.75);
    border: 2px solid #cbcfde;
  }
  &_wrapper {
  }
  &_title {
    display: flex;
    justify-content: center;
    width: 100%;
    border-bottom: 2px #cbcfde;
    background: #f7f8fe;
    border-radius: 8px;
    padding: 20px 0;
    h3 {
      font-size: 20px !important;
      font-weight: 600 !important;
    }
  }
  &_body {
    form {
      display: grid;
      margin: 10px 0;
      min-height: 180px;
      .email_label {
        width: 100%;
        font-size: 20px;
      }
      .email_input {
        width: 100%;
        padding: 9px;
        margin-bottom: 5px;
        font-size: 18px;
        border: 2px solid #cbcfde;
        border-radius: 6px;
        margin-top: 5px;
        outline: none;
      }
      .ErrorMessage {
        color: #ff8181;
      }
      .HitronErrorMessage {
        color: #004c97;
      }
    }
  }
  &_footer {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    .cancel_btn {
      width: 245px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      height: 50px;
      padding: 0;
      margin: 0;
      border: none;
      outline: none;
      font-size: 16px;
    }
    .authorize_gateway_btn {
      font-size: 16px;
      width: 245px;
      display: flex;
      justify-content: center;
      align-items: center;

      margin-left: 18px;
      border-radius: 8px;
      height: 50px;
      padding: 0;
      margin: 0;

      outline: none;
      .loading-spinner {
        border-top: 3px solid #6c83ff;
      }
    }
  }
}
