.customer_stats_card {
  display: flex;
  position: relative;
  column-gap: 6px;
  justify-content: space-between;
  font-family: SFProDisplay-Light, sans-serif;
  font-style: normal;
  box-sizing: border-box;
  height: 120px;
  font-weight: 700;
  &:hover {
    cursor: pointer;
  }

  .title {
    font-size: 24px;
    line-height: 29px;
    color: #6b7a9f !important;
    font-family: SFProDisplay-SemiBold, sans-serif;
  }

  .filter_type {
    font-family: SFProDisplay-SemiBold, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    color: #6b7a9f;
    margin: 0;
    // margin-top: 20px;
  }

  .card_value {
    margin: 0;
    font-family: SFProDisplay-Bold, sans-serif;
    font-size: 48px;
    line-height: 1;
    color: #282828;
  }
  .card_title {
    margin: 0;
    margin-bottom: 24px;
    font-family: SFProDisplay-Bold, sans-serif;
    font-style: normal;
    font-size: 22px;
    line-height: 29px;
    color: #282828;
    text-wrap: nowrap;
  }
}
.logo {
  position: absolute;
  right: 0px;
  bottom: 22px;
}
.hitron_logo {
  position: absolute;
  right: 0px;
  bottom: 0px;
}
