.web-supervision-wrapper {
  background-color: #fff;
  padding: 26px 28px;
  border-radius: 10px;

  h1 {
    margin-bottom: 26px;
    font-size: 24px;
    font-family: SFProDisplay-Bold, sans-serif !important;
  }
  .web-supervision-inner-wrapper {
    display: flex;
    overflow-x: auto;
    padding-bottom: 12px;
    gap: 0 49px;
  }
}

.web-supervision-till-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  .inner-circle {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 15px 0;
    .web-supervision-circle {
      width: 66px;
      height: 66px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 24px;
      line-height: 66px;
      @apply font-sf-regular;
      border-radius: 50%;
      color: #fff;
    }
  }
  .web-supervision-till-title {
    font-size: 18px;
    width: 110px;
    text-align: center;
    font-family: SFProDisplay-Light, sans-serif !important;
  }
}

.ant-progress-inner {
  width: 90px !important;
  height: 90px !important;
}

.tbl {
  min-width: 1200px !important;
}

.custom-data-grid {
  border: none;
}

.network-map-filter {
  box-shadow: 1.5px 1.5px 3px 0px rgba(174, 174, 192, 0.4), -1px -1px 3px 0px #fff;
}

.speedtest-button {
  transform: skew(-21deg);
}

.device-information-table {
  width: 100%;
}

.device-information-table > tbody > tr > td {
  padding: 10px 15px;
  font-size: 14px;
}

.alert-font {
  font-size: 18px;
  font-weight: 700;
  line-height: 21.48px;
}

.alert-font-desc {
  font-size: 14px;
  font-weight: 500;
  line-height: 16.71px;
}
.white-text {
  color: #ffffff;
}

.alert-summary-accordian {
  border-bottom: 1px solid #eff2fc;
  width: 756px;
}

.alert-box-summary {
  cursor: pointer;
  padding: 20px;
  width: 100%;
  height: 70px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.alert-critical-summary {
  background: #ff8181;
}
.alert-warning-summary {
  background: #ff974c;
  margin-top: 10px;
}
.drawer-z-index {
  z-index: 1000 !important;
  box-shadow: 3px 3px 6px 0px rgba(174, 174, 192, 0.4), -1px -1px 3px 0px #fff;
  background: #eff2fc;
  @apply font-sf-regular;
}
// [role=tabpanel]{
//   width: 85%!important;
// }

.inline-checkbox {
  display: inline-flex;
  align-items: center;
  row-gap: 4px;
}

.active-filter {
  box-shadow: 1.5px 1.5px 3px 0px rgba(174, 174, 192, 0.4), -1px -1px 3px 0px #fff;
  padding: 6px 12px;
  border-radius: 10px;
  background: #a3d696;
  color: #ffffff !important;
  font-size: 14px;
}

.inactive-filter {
  padding: 6px 12px;
  border-radius: 10px;
  font-size: 14px;
}

.highcharts-tick {
  display: none !important;
}
