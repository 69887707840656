.log_out_btn {
  // border: 2px solid #ff8181;
  margin-right: 20px;
  padding: 14px;
  border-radius: 15px;
  // color: #ff8181;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    color: #ffffff !important;
    background-color: #ff8181;
    border: 2px solid white;
  }
}
.log_out_btn_hitron {
  margin-right: 20px;
  padding: 14px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    color: #ffffff !important;
    background-color: #004c97;
    border: 2px solid white;
  }
}

.log_out_wrapper {
  .ant-modal-content {
    border-radius: 12px;
    box-shadow: 0px 12px 25px -14px rgba(255, 255, 255, 0.75);
    -webkit-box-shadow: 0px 12px 25px -14px rgba(255, 255, 255, 0.75);
    -moz-box-shadow: 0px 12px 25px -14px rgba(255, 255, 255, 0.75);
    border: 2px solid #cbcfde;
  }
  .log_out {
    &_title {
      display: flex;
      justify-content: center;
      width: 100%;
      border-bottom: 2px #cbcfde;
      background: #f7f8fe;
      border-radius: 8px;
      padding: 20px 0;
      h3 {
        font-size: 20px !important;
        font-weight: 600 !important;
      }
    }
    &_body_wrapper {
      .conformation_text {
        font-size: 18px !important;
        font-weight: 600 !important;
      }
      .commit_text {
        font-size: 16px !important;
        font-weight: 400 !important;
      }
      .log_out_body {
        text-align: center;
        margin: 40px 0;
      }
      .log_out_footer {
        display: flex;
        justify-content: space-between;
        .cancel_btn {
          width: 245px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 8px;
          height: 50px;
          padding: 0;
          margin: 0;
          border: none;
          outline: none;
          font-size: 16px;
        }
        .logout_btn {
          font-size: 16px;
          width: 245px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 18px;
          border-radius: 8px;
          height: 50px;
          padding: 0;
          margin: 0;
          outline: none;
        }
      }
    }
  }
}
