.dropdown-btn {
  margin-right: 18px;
  border: 2px solid #eff2fc !important;
  background: #ffffff;
  border-radius: 15px;
  padding: 17.5px;
  font-size: 18px;
  line-height: 21px;
  outline: none;
  cursor: pointer;
  .down-arrow {
    margin-left: 5px;
  }
}
.drop-down {
  min-width: 100%;
  max-height: 370px;
  border: none;
  border-radius: 15px;
  background: #ffffff;
  transform: translateY(0px) scale(1.005) translateZ(0);
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.1);
  z-index: 99999999;
  overflow-y: scroll !important;
  &::-webkit-scrollbar {
    display: block;
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: #eff2fc00;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ababab;
    border-radius: 8px;
    border-right: none;
    border-left: none;
  }
  &::-webkit-scrollbar-track-piece:end {
    background: transparent;
    margin-bottom: 10px;
  }

  &::-webkit-scrollbar-track-piece:start {
    background: transparent;
    margin-top: 10px;
  }
  &-option {
    padding: 15px 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer !important;
    &:hover {
      border-color: #eff2fc;
      outline: none;
      background-color: #f4f5fa;
    }
    .online_tag {
      display: flex;
      justify-content: center;
      padding: 8px;
      box-sizing: border-box;
      font-size: 14px;
      border-radius: 10px;

      border: none;
      margin-left: 10px;
      width: 70px;
    }
    .offline_tag {
      display: flex;
      justify-content: center;
      padding: 8px;
      box-sizing: border-box;
      margin-left: 10px;
      font-size: 14px;
      border-radius: 10px;

      width: 70px;
    }
    .master_tag {
      display: flex;
      justify-content: center;
      padding: 8px;
      box-sizing: border-box;
      margin-left: 40px;
      font-size: 14px;
      border-radius: 10px;

      border: none;
      width: 70px;
    }
    .satellite_tag {
      display: flex;
      justify-content: center;
      padding: 8px;
      box-sizing: border-box;
      width: 70px;
      margin-left: 40px;
      font-size: 14px;
      border-radius: 10px;
    }
    .down-arrow {
      margin-left: 20px;
      margin-right: 10px;
    }
    .empty-span {
      width: 45px;
    }
  }
}
