.time-supervision-wrapper{
    background-color: #fff;
    border-radius: 10px;
    min-height: 400px;
    padding: 26px 28px 0;
    width: 100%;
    &.iscard{
        // max-width: 820px;
    }
    .time-supervision-inner-wrapper{
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        .time-supervision-title{
            font-size: 24px;
            font-family: SFProDisplay-Bold, sans-serif !important;
        }
    }
    .chart-main-block{
        width: 100%;
        margin-top: 12px;
    }

    table tr td:nth-child(1) {
        min-width: 250px;
    }
    table tr td:nth-child(2) {
        min-width: 250px;
    }
    table tr td:nth-child(3) {
        min-width: 250px;
    }
}