.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.cursor-pointer {
  cursor: pointer;
}

.justify-end {
  justify-content: end;
}

.w-full {
  width: 100%;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.align-center {
  align-items: center;
}

.text-center {
  text-align: center;
}

.Alerts-Summary-Card {
  transition: all 0.6s ease-out;
  &-AllCustomer-hitron {
    &:hover {
      transform: translateY(0px) scale(1.005) translateZ(0);
      border: 2px solid rgba(20, 76, 151, 0.33) !important;
    }
  }

  &-AllDevices-hitron {
    &:hover {
      border: 2px solid rgba(20, 76, 151, 0.33) !important;
      transform: translateY(0px) scale(1.005) translateZ(0);
    }
  }

  &-Online-hitron {
    &:hover {
      border: 2px solid rgba(0, 204, 102, 0.33) !important;
      transform: translateY(0px) scale(1.005) translateZ(0);
    }
  }

  &-Offline-hitron {
    &:hover {
      border: 2px solid rgba(115, 115, 115, 0.33) !important;
      transform: translateY(0px) scale(1.005) translateZ(0);
    }
  }

  &-notRegistered-hitron {
    &:hover {
      border: 2px solid rgba(115, 115, 115, 0.33) !important;
      transform: translateY(0px) scale(1.005) translateZ(0);
    }
  }

  &-needUpdate-hitron {
    &:hover {
      border: 2px solid rgba(115, 115, 115, 0.33) !important;
      transform: translateY(0px) scale(1.005) translateZ(0);
    }
  }
  &:hover {
    transform: translateY(0px) scale(1.005) translateZ(0);
    box-shadow: 0px 14px 26px 5px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0px 14px 26px 5px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 14px 26px 5px rgba(0, 0, 0, 0.1);
  }

  &-AllCustomer {
    &:hover {
      transform: translateY(0px) scale(1.005) translateZ(0);
      border: 2px solid rgba(255, 151, 76, 0.33) !important;
    }
  }

  &-AllDevices {
    &:hover {
      border: 2px solid rgba(108, 131, 255, 0.33) !important;
      transform: translateY(0px) scale(1.005) translateZ(0);
    }
  }

  &-Online {
    &:hover {
      border: 2px solid rgba(163, 214, 148, 0.33) !important;
      transform: translateY(0px) scale(1.005) translateZ(0);
    }
  }

  &-Offline {
    &:hover {
      border: 2px solid rgba(255, 129, 129, 0.33) !important;
      transform: translateY(0px) scale(1.005) translateZ(0);
    }
  }

  &-notRegistered {
    &:hover {
      border: 2px solid rgba(206, 223, 252, 0.33) !important;
      transform: translateY(0px) scale(1.005) translateZ(0);
    }
  }

  &-needUpdate {
    &:hover {
      border: 2px solid rgba(107, 122, 159, 0.33) !important;
      transform: translateY(0px) scale(1.005) translateZ(0);
    }
  }
}

* {
  scrollbar-width: thin;
  scrollbar-color: #afaeaf;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar-track {
  background: #e5e7eb00;
}

.m-auto {
  margin: auto;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

@for $i from 1 through 100 {
  // padding
  .p-#{$i} {
    padding: #{$i}px;
  }
  .pr-#{$i} {
    padding-right: #{$i}px;
  }
  .pl-#{$i} {
    padding-left: #{$i}px;
  }
  .pb-#{$i} {
    padding-bottom: #{$i}px;
  }
  .pt-#{$i} {
    padding-top: #{$i}px;
  }
  .px-#{$i} {
    padding-left: #{$i}px;
    padding-right: #{$i}px;
  }
  .py-#{$i} {
    padding-top: #{$i}px;
    padding-bottom: #{$i}px;
  }
  // margin
  .m-#{$i} {
    margin: #{$i}px;
  }
  .mr-#{$i} {
    margin-right: #{$i}px;
  }
  .ml-#{$i} {
    margin-left: #{$i}px;
  }
  .mb-#{$i} {
    margin-bottom: #{$i}px;
  }
  .mt-#{$i} {
    margin-top: #{$i}px;
  }
  .mx-#{$i} {
    margin-left: #{$i}px;
    margin-right: #{$i}px;
  }
  .my-#{$i} {
    margin-top: #{$i}px;
    margin-bottom: #{$i}px;
  }
  .row-gap-#{$i} {
    row-gap: #{$i}px;
  }
  //width
  // .w-#{$i} {
  //   width: #{$i} * 1%;
  // }
  .w-#{$i}-px {
    width: #{$i}px;
  }
}
.ant-modal,
.ant-modal-body {
  .ant-spin-text {
    word-wrap: normal !important;
  }
}

.coming-soon-cls {
  opacity: 0.5;
  cursor: not-allowed;
  &:hover {
    background: #e7e8ea !important;
    opacity: 0.7 !important;
    .icon-cls {
      background: #ffffff;
      height: 34px;
      width: 34px;
      padding: 8px;
      border-radius: 4px;
    }
  }
}
.Toastify__toast-container {
  z-index: 9999999 !important;
}
