.SliderRoot {
  position: relative;
  display: flex;
  align-items: center;
  user-select: none;
  touch-action: none;
  width: 200px;
  height: 20px;
}

.SliderTrack {
  background-color: #6b7a9f;
  position: relative;
  flex-grow: 1;
  border-radius: 9999px;
  height: 6px;
}

.SliderRange {
  position: absolute;
  // background-color: #6C83FF;
  border-radius: 9999px;
  height: 100%;
}

.SliderThumb {
  display: block;
  width: 20px;
  height: 20px;
  background-color: white;
  box-shadow: 0 2px 10px #6b7a9f;
  border-radius: 10px;
}
.SliderThumb:hover {
  background-color: #ffffff;
}
.SliderThumb:focus {
  outline: none;
  box-shadow: 0 0 0 5px #6b7a9f;
}
