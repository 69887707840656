.gate_way_logs {
  padding: 24px;
  background: #ffffff;
  border-radius: 4px;
  min-height: 75vh;
  transform: translateY(0px) scale(1) translateZ(0);
  box-shadow: 0px 14px 26px 5px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 14px 26px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 14px 26px 5px rgba(0, 0, 0, 0.1);
}
.empty-row{
    height: 250px;
}

.pc-table {
    width: 100%;
    min-width: 650px;
    border-spacing: 0;
    border: none;
  
    color: #282828;
    overflow: hidden;
  
    thead {
      tr {
        border-bottom: 2px solid #e0e0e0;
  
        td {
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 21px;
          border: none;
          padding: 25px 25px;
          color: #969696;
          @apply font-sf-light;
        }
      }
    }
  
    tbody {
      border: none;
      color: #282828 !important;
  
      tr {
        background: #ffffff;
        border-radius: 10px;
        border-bottom: 2px solid #e0e0e0;
        margin-bottom: 10px;
  
        td {
          border: none;
          padding: 16px 16px;
          text-align: left;
          @apply font-sf-light;
        }
  
        &:not(:last-child) {
          margin-bottom: 4px;
        }
      }
    }
  }